let timeout: any = null;
let nextRun: number;
let remaining: number | null;
let myCallback: any;

export const startTimer = (callback: any, delay: number) => {
  if (timeout) {
    throw('A timeout is still running');
  } else {
    nextRun = new Date().getTime() + delay;
    myCallback = callback;
    // console.log(nextRun);
    timeout = setTimeout(() => {
      myCallback();
      // console.log('run', new Date().getTime(), new Date().getTime() - nextRun);
      timeout = null;
      remaining = null;
      myCallback = null;
    }, delay);
  }
}

export const pauseTimer = () => {
  if (!timeout) {
    throw('No timer has been started');
  } else {
    clearTimeout(timeout);
    const currentTime = new Date().getTime();
    remaining = nextRun - currentTime;
    // console.log('Remaining', remaining);
  }
}

export const resumeTimer = () => {
  if (!remaining) {
    throw('No timer has been started');
  } else {
    nextRun = new Date().getTime() + remaining;
    timeout = setTimeout(() => {
      myCallback();
      // console.log('run on resume');
      timeout = null;
      remaining = null;
      myCallback = null;
    }, remaining);
  }
}

export const stopTimer = () => {
  if (!timeout) {
    throw('No timer has been started');
  } else {
    // console.log('Timer has been stopped');
    clearTimeout(timeout);
    timeout = null;
  }
}