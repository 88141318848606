
/* eslint-disable */
import { defineComponent, onBeforeUnmount, onMounted, Ref, ref, watch } from 'vue';
import { pauseTimer, resumeTimer, startTimer, stopTimer } from '@/ts/Timer';
import { useRouter } from 'vue-router';
import { request, requestFixed } from '@/ts/api';
import { AxiosError } from 'axios';
import { HubConnection } from '@microsoft/signalr';
import { signalr } from '@/ts/signalr';
import Popup from '@/components/Popup.vue';

export default defineComponent({
  components: {
    Popup
  },
  setup() {
    let signal: HubConnection;

    //#region Data
    const showControls = ref(false);
    const showSettings = ref(false);
    const showRemote = ref(false);
    const audioControl: Ref<any> = ref(null);
    const songIndex = ref(0);
    const songSrc = ref('');
    const songPlaying = ref(false);
    const songFIllWidth = ref(0);

    const showLoginPopup = ref(false);
    const loggingIn = ref(false);
    const loginFolder = ref('');
    const loginPassword = ref('');
    const loginError = ref('');
    const accessLevel = ref('');

    const showRegisterPopup = ref(false);
    const registerFolder = ref('');
    const registerUserPassword = ref('');
    const registerAdminPassword = ref('');
    const registering = ref(false);
    const registerError = ref('');
    const registerSuccess = ref('');

    const transitionStyleList = ref([ 'slideLeft', 'zoom', 'slideUp', 'zoomout' ]);
    const selectedTransition = ref('slideLeft');

    const showEven = ref(false);

    const currentImageIndex = ref(0);
    const nextImageIndex = ref(0);
    const evenImage: Ref<any> = ref(null);  
    const oddImage: Ref<any> = ref(null);
    const evenImageError = ref(false);
    const oddImageError = ref(false);
    const slideshowPlaying = ref(true);

    const canShare = ref(false);
    const sharing = ref(false);
    const sharePassword = ref('');
    const shareError = ref('');

    const router = useRouter();

    //#region Settings Inputs
    const folderNameInput = ref('');
    const intervalInput = ref(3);
    const randomImageChecked = ref(false);
    const allowRemoteControl = ref(false);

    const allRemoteControls = ref([
      { text: 'Play/Pause Slideshow', key: 'playPauseSlideshow', description: 'Play or Pause the current slideshow (Songs will still be playing)' },
      { text: 'Play/Pause Song', key: 'playPauseSong', description: 'Paly or Pause the song (Images will still be changing)' },
      { text: 'Next Song', key: 'nextSong', description: 'Change to the next available song' },
      { text: 'Previous Song', key: 'prevSong', description: 'Go back to previous available song' },
      { text: 'Restart Slideshow', key: 'restart', description: 'Restart the slideshow without interupting the slideshow flow' },
      { text: 'Hard Reload', key: 'reload', description: 'Reload the whole page (Use this with caution since the slideshow and song will reset back to the first item)' },
      { text: 'Show/Hide Controls', key: 'controls', description: 'Show or Hide the controls' },
    ])

    const settingsJson = ref();
    const settingsError = ref('');

    const showDeleteFolderPopup = ref(false);
    //#endregion
    //#endregion

    //#region Lifecycle
    onMounted(async () => {
      signal = signalr();
      canShare.value = typeof navigator.share == 'function' ? true : false;

      window.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.key == ' ') {
          songPlayPauseClicked();
        }
      })

      const allowRemote = localStorage.getItem('remote');
      if (!allowRemote) {
        localStorage.setItem('remote', 'false');
      } else {
        allowRemoteControl.value = allowRemote == 'true';
      }

      const settingsDetails = localStorage.getItem('settings');
      if (!settingsDetails) {
        const query = router.currentRoute.value.query;
        if (query['login']) {
          const autoLogin = atob(query['login'].toString()).split(',');
          loginFolder.value = autoLogin[0];
          loginPassword.value = autoLogin[1];
          loginClicked();
        } else {
          showLoginPopup.value = true;
        }
      } else {
        settingsJson.value = JSON.parse(settingsDetails);
        showControls.value = true;
        accessLevel.value = settingsJson.value?.access || 'READONLY';

        document.title = `${settingsJson.value?.folder} - Slideshow`;

        restartSlideshow();
        getSong();
      }
    })
    onBeforeUnmount(() => {
      signal.off('remoteControl');
    })
    //#endregion
    
    //#region Methods
    const evenImageLoaded = () => {
      setRandomTransition();
      
      evenImageError.value = false;
      showEven.value = !showEven.value;
      startTimer(loadOddImage, settingsJson.value?.interval * 1000);
    }
    const oddImageLoaded = () => {
      setRandomTransition();
      
      oddImageError.value = false;
      showEven.value = !showEven.value;
      startTimer(loadEvenImage, settingsJson.value?.interval * 1000);
    }

    const restartSlideshow = async () => {
      try {
        stopTimer();
      } catch (e) {
        console.log(e);
      }
      evenImage.value.src = '';
      oddImage.value.src = '';
      showEven.value = false;

      setTimeout(() => {
        showControls.value = false;
        loadEvenImage();
        slideshowPlaying.value = true;
      }, 2000);
    }

    const setRandomTransition = () => {
      let rand = Math.floor(Math.random() * transitionStyleList.value.length);
      selectedTransition.value = transitionStyleList.value[rand];
    }

    const getImageAtIndex = async (index: number) => {
      try {
        const imageLink = (await request.post('/GetImageLink', {
          folder: settingsJson.value?.folder,
          imageIndex: index
        })).data;
        return imageLink;
      } catch (e: any) {
        console.log((e as AxiosError).response?.data);
        return {};
      }
    }
    const getRandomImage = async () => {
      try {
        const imageLink = (await request.post('/GetImageLink', {
          folder: settingsJson.value?.folder,
          currentIndex: currentImageIndex.value
        })).data;
        return imageLink;
      } catch (e: any) {
        console.log((e as AxiosError).response?.data);
        return {};
      }
    }

    const imgMouseDown = () => {
      if (accessLevel.value) showControls.value = !showControls.value;
    }

    const saveSettingsClicked = async () => {
      settingsError.value = '';

      if (!folderNameInput.value) {
        settingsError.value = 'Please enter your folder';
        return;
      }

      const checkFolderName = await request.get(`/CheckFolderName?folder=${folderNameInput.value}`);
      if (!checkFolderName?.data) {
        settingsError.value = `Folder ${folderNameInput.value} does not exists.`;
        return;
      }

      if (settingsJson.value?.folder != folderNameInput.value) {
        restartSlideshow();
      }

      settingsJson.value = {
        folder: folderNameInput.value,
        interval: intervalInput.value ? (intervalInput.value < 3 ? 3 : intervalInput.value > 10 ? 10 : intervalInput.value) : 3,
        randomImage: randomImageChecked.value
      };
      localStorage.setItem('settings', JSON.stringify(settingsJson.value));
      document.title = `${settingsJson.value?.folder} - Slideshow`;

      showSettings.value = false;
    }
    const changeFolderClicked = () => {
      localStorage.removeItem('settings');
      localStorage.setItem('remote', 'false');
      
      showSettings.value = false;
      showLoginPopup.value = true;

      showControls.value = false;
      accessLevel.value = '';

      loginFolder.value = '';
      loginPassword.value = '';

      settingsJson.value = null;
      
      evenImage.value.src = '';
      oddImage.value.src = '';

      document.title = `Slideshow`;
    }

    const loadEvenImage = async () => {
      let imageObj: any;
      if (settingsJson.value?.randomImage) {
        imageObj = await getRandomImage(); // For random
      } else {
        imageObj = await getImageAtIndex(nextImageIndex.value); // For next image in the sequence
      }
      
      evenImage.value.src = imageObj.img;
      currentImageIndex.value = imageObj.index;
      nextImageIndex.value = 'nextIndex' in imageObj ? imageObj.nextIndex : currentImageIndex.value + 1;
    }
    const loadOddImage = async () => {
      let imageObj: any;
      if (settingsJson.value?.randomImage) {
        imageObj = await getRandomImage(); // For random
      } else {
        imageObj = await getImageAtIndex(nextImageIndex.value); // For next image in the sequence
      }
      
      oddImage.value.src = imageObj.img;
      currentImageIndex.value = imageObj.index;
      nextImageIndex.value = 'nextIndex' in imageObj ? imageObj.nextIndex : currentImageIndex.value + 1;
    }

    const addImageClicked = () => {
      const path = router.resolve('/Photos');
      window.open(path.href, '_blank');
    }

    const loginClicked = async () => {
      loginError.value = '';
      loggingIn.value = true;

      try {
        const access = (await request.post('/GetFolderAccess', {
          folder: loginFolder.value,
          password: loginPassword.value
        })).data;
        accessLevel.value = access;
        showLoginPopup.value = false;

        settingsJson.value = {
          folder: loginFolder.value,
          access: accessLevel.value,
          interval: 3,
          randomImage: false
        };
        localStorage.setItem('settings', JSON.stringify(settingsJson.value));

        document.title = `${settingsJson.value?.folder} - Slideshow`;
        loggingIn.value = false;

        restartSlideshow();
      } catch (err) {
        showLoginPopup.value = true;
        loginError.value = (err as AxiosError).response?.data as string;
        loggingIn.value = false;
      }
    }
    const registerClicked = async () => {
      registerError.value = '';
      registering.value = false;
      
      try {
        registering.value = true;
        (await request.post('/CreateFolder', {
          folder: registerFolder.value,
          password: registerUserPassword.value,
          addpassword: registerAdminPassword.value
        })).data;

        registerSuccess.value = 'Created your folder, you can now connect to your newly created folder';
        registering.value = false;
      } catch (e) {
        registerError.value = (e as AxiosError).response?.data as string;
        registering.value = false;
      }
    }

    const deleteFolderPopupButtonClicked = async (text: string) => {
      showDeleteFolderPopup.value = false;
      switch (text) {
        case 'Delete':
          try {
            await request.delete(`/DeleteFolder?folder=${settingsJson.value?.folder}`);
            changeFolderClicked();
          } catch (e) {
            console.log((e as AxiosError).response?.data);
          }

          break;
      }
    }

    const remoteControlReceived = (data: any) => {
      if (data.folder == settingsJson.value?.folder) {
        switch (data.control) {
          case 'playPauseSlideshow':
            slideshowPlaying.value = !slideshowPlaying.value;
            showControls.value = true;
            setTimeout(() => {
              showControls.value = false;
            }, 1000);
            break;
          case 'playPauseSong':
            songPlayPauseClicked();
            break;
          case 'nextSong':
            getSong(true);
            break;
          case 'prevSong':
            getSong(false);
            break;
          case 'restart':
            restartSlideshow();
            break;
          case 'reload':
            window.location.reload();
            break;
          case 'controls':
            showControls.value = !showControls.value;
            break;
          case 'volume':
            audioControl.value.volume = data.value / 100;
            break;
        }
      }
    }
    const remoteClicked = async (key: string) => {
      await requestFixed.post('https://api.reeqzan.com/Sender/SendUpdate', {
        method: 'remoteControl',
        data: {
          folder: settingsJson.value?.folder,
          control: key
        }
      })
    }
    const volumeChangedRemotely = async (e: any) => {
      requestFixed.post('https://api.reeqzan.com/Sender/SendUpdate', {
        method: 'remoteControl',
        data: {
          folder: settingsJson.value?.folder,
          control: 'volume',
          value: e.target.valueAsNumber
        }
      })
    }

    const shareClicked = async () => {
      shareError.value = '';

      if (!sharePassword.value) {
        shareError.value = 'Please enter the share password';
        return;
      }

      sharing.value = true;

      try {
        const shareLogin = await request.get(`/GetAutoLogin?folder=${folderNameInput.value}&pass=${sharePassword.value}`);
        await navigator.share({
          text: 'Share Slideshow',
          url: `${window.location.origin}?login=${shareLogin.data}`
        });
      } catch (e: any) {
        shareError.value = ((e as AxiosError).response?.data as string) || 'An error occur';
      }

      sharing.value = false;
    }
    //#endregion

    //#region Audio
    const getSong = async (next?: boolean) => {
      try {
        songSrc.value = '';
        if (songPlaying.value) audioControl.value.pause();
        if (next != undefined) songIndex.value = next ? songIndex.value + 1 : songIndex.value - 1;

        const songLink = (await request.get(`/GetSongLink?folder=${settingsJson.value.folder}&index=${songIndex.value}`)).data;
        songSrc.value = songLink.song;
        songIndex.value = songLink.index;
      } catch (error) {
        console.log((error as AxiosError).response?.data);
      }
    }
    const audioLoaded = () => {
      audioControl.value.play();
    }
    const audioError = () => {
      console.log('audioError');
    }
    const songPlayPauseClicked = () => {
      songPlaying.value = !songPlaying.value;
      if (songPlaying.value) audioControl.value.play();
      else audioControl.value.pause();
    }
    const songTimeUpdate = () => {
      songFIllWidth.value = audioControl.value.currentTime / audioControl.value.duration * 100;
    }
    const songBarClicked = (e: any) => {
      const clickPerc = e.offsetX / e.target.getBoundingClientRect().width;
      const duration = audioControl.value.duration;
      const newTime = duration * clickPerc;
      audioControl.value.currentTime = newTime;
    }
    //#endregion

    //#region Watcher
    watch(showSettings, (val) => {
      if (!val) {
        folderNameInput.value = '';
        settingsError.value = '';
      } else {
        folderNameInput.value = settingsJson.value?.folder || '';
        intervalInput.value = settingsJson.value?.interval || 3;
        randomImageChecked.value = settingsJson.value?.randomImage;
      }
    })
    watch(slideshowPlaying, (val) => {
      if (val) {
        resumeTimer();
      } else {
        pauseTimer();
      }
    })
    watch(showRegisterPopup, (val) => {
      if (val) {
        registerFolder.value = '';
        registerUserPassword.value = '';
        registerAdminPassword.value = '';

        registerError.value = '';
        registerSuccess.value = '';
      } 
    })
    watch(allowRemoteControl, (val) => {
      localStorage.setItem('remote', val ? 'true' : 'false');
      if (val) {
        signal.on('remoteControl', remoteControlReceived);
      } else {
        signal.off('remoteControl')
      }
    })
    //#endregion
    
    return {
      //#region Data
      showControls,
      showSettings,
      showRemote,
      audioControl,
      songSrc,
      songPlaying,
      songFIllWidth,

      showLoginPopup,
      loggingIn,
      loginFolder,  
      loginPassword,  
      loginError,
      accessLevel,

      showRegisterPopup,
      registerFolder,
      registerUserPassword,
      registerAdminPassword,
      registering,
      registerError,
      registerSuccess,

      selectedTransition,

      showEven,
      
      evenImage,
      oddImage,
      evenImageError,
      oddImageError,

      folderNameInput,
      intervalInput,
      randomImageChecked,
      allowRemoteControl,

      settingsError,
      showDeleteFolderPopup,
      allRemoteControls,

      slideshowPlaying,

      canShare,
      sharing,
      sharePassword,
      shareError,
      //#endregion
      
      //#region Methods
      evenImageLoaded,
      oddImageLoaded,

      imgMouseDown,

      saveSettingsClicked,
      changeFolderClicked,

      addImageClicked,

      loginClicked,
      registerClicked,

      deleteFolderPopupButtonClicked,
      remoteClicked,
      volumeChangedRemotely,

      shareClicked,
      //#endregion
    
      //#region Audio
      getSong,
      audioLoaded,
      audioError,
      songPlayPauseClicked,
      songTimeUpdate,
      songBarClicked,
      //#endregion
    }
  },
})
